@font-face {
  font-family: Lufga;
  src: url(../Lufga-Regular.otf);
}
.team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #e5e5e5;
  /* text-align: left; */
  background-attachment: fixed;
  padding: 100px 200px 100px 0px;
}
.our__team {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  /* identical to box height, or 58px */

  text-transform: uppercase;

  color: #252525;
}
.team__img {
  width: 120%;
  height: auto;
}
.team__descp {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column !important;
}
.team__descp__title {
  font-family: Lufga;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  text-transform: uppercase;

  color: #252525;
}
.team__descp__text {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
  width: 700px;
  color: #2f2f2f;
  margin-top: 10px;
}
.team__img__align {
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .team {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #e5e5e5;
    /* text-align: left; */
    padding: 100px 50px 100px 50px;
  }
  .our__team {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 58px */

    text-transform: uppercase;

    color: #252525;
  }
  .team__img {
    width: 80vw;
    height: auto;
  }
  .team__descp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
  }
  .team__descp__title {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 31px;
    text-transform: uppercase;
    margin: 10px 0 10px 0;
    color: #252525;
  }
  .team__descp__text {
    font-family: Lufga;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    width: 70%;
    color: #2f2f2f;
    /* padding: 0 50px 0 50px; */
  }
  .team__img__align {
    text-align: center;
  }
}
