@font-face {
  font-family: Lufga;
  src: url(../Lufga-Regular.otf);
}
@font-face {
  font-family: lufga-thin;
  src: url(../../Lufga-Thin.otf);
}
.roadmap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-attachment: fixed;
  z-index: 10000 !important;

  background-color: #000000;
}
.our__roadmap {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  /* padding: 0 200px 0 200px; */
  line-height: 120%;
  /* identical to box height, or 58px */
  margin: 100px 0 50px 0;
  text-transform: uppercase;

  color: #ffffff;
}
.text__roadmap {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 160% */
  /* padding: 0 200px 0 200px; */
  width: 60%;
  text-align: justify;

  color: #ffffff;
}
.vl1 {
  border-left: 3px solid white;
  height: 50px;
  /* margin-top: 100px; */
}
.vl2 {
  border-left: 3px solid white;
  height: 80px;
  margin-top: 10px;
}
.title1 {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-transform: uppercase;
  margin: 10px 0 20px 0;
  color: #ffffff;
}
.text1 {
  font-family: lufga-thin;
  font-style: normal;
  font-weight: 300 !important;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  width: 785px;
  color: #ffffff;
}

#bound-one {
  background: #000;
}
#bound-two {
  background: hsl(0, 21%, 84%);
  display: none;
}
#bound-three {
  display: none;
  background: #d0d6b3;
}
.scroll-bound {
  height: 1500px;
}

.content {
  margin-top: 0;
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#video {
  position: sticky;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
}

.main-container {
  height: 100%;
  display: flex;
}

.left-container {
  flex: 1 1 0;
}

.right-container {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}
.leftgrid {
  position: relative;
  /* right: 14vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
}
/* @media screen and (max-width: 800px) {} */

@media screen and (max-width: 1024px) {
  .our__roadmap {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    padding: 0 50px 0 50px !important;
    line-height: 120%;
    /* identical to box height, or 58px */
    margin: 100px 0 50px 0;
    text-transform: uppercase;

    color: #ffffff;
  }
  .text__roadmap {
    font-family: Lufga;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 32px;
    /* or 160% */
    width: 80%;
    padding: 0 !important;
    text-align: center;
    margin-bottom: 50px;
    color: #ffffff;
  }
  .vl1 {
    border-left: 3px solid white;
    height: 20px;
    margin-top: 10px;
  }
  .vl2 {
    border-left: 3px solid white;
    height: 50px;
    margin-top: 10px;
  }
  .title1 {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 31px;
    text-transform: uppercase;
    margin: 10px 0 20px 0;
    color: #ffffff;
  }
  .text1 {
    font-family: Lufga;
    font-style: normal;
    font-weight: 300;
    font-size: 12px !important;
    line-height: 26px;
    text-align: center;
    width: 70%;
    color: #ffffff;
  }

  #bound-one {
    background: #000;
  }
  #bound-two {
    background: hsl(0, 21%, 84%);
    display: none;
  }
  #bound-three {
    display: none;
    background: #d0d6b3;
  }
  .scroll-bound {
    height: 0;
  }

  .content {
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #video {
    position: sticky;
    width: 100%;
    height: 100%;
  }

  video {
    width: 100%;
  }

  .main-container {
    height: 100%;
    display: flex;
  }

  .left-container {
    flex: 1 1 0;
  }

  .right-container {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }
  .leftgrid {
    position: relative;
    /* right: 14vw; */

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
  }
  .video_grid {
  }
}
