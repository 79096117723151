.section1 {
  background-image: url(./Group\ 86.png);
  background-position: center;
  /* background: #e5e5e5; */

  background-size: cover;
  /* background-position: center; */
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  position: relative;
  z-index: 10000 !important;
  /* border-top: 4px solid black; */
}
.yolk__bag {
  width: 500px;
  height: auto;
  position: absolute;
  bottom: 0%;
  background-attachment: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
.yolk {
  position: absolute;
  top: 20%;
  z-index: 100;
  left: 50%;
  width: 600px;
  height: auto;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1024px) {
  .section1 {
    background-image: none;
    background: #f1f1e9;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .yolk {
    width: 80vw;
    height: auto;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .yolk__bag {
    display: flex;
    width: 100vw;
    height: auto;
    position: absolute;
    bottom: 0 !important;
    margin-top: 5vh;
    /* left: 50%;
    transform: translate(-50%, -42%); */
  }
}
@media screen and (max-width: 479px) {
  .section1 {
    background-image: none;
    background: #f1f1e9;
    background-size: cover;
    width: 100%;
    height: 95vh;
    position: relative;
  }
  .yolk {
    width: 80vw;
    height: auto;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .yolk__bag {
    display: block;
    width: 100vw;
    height: auto;
    position: absolute;
    bottom: 0%;
    left: 50%;
    /* transform: translate(-50%, -38%) !important; */
  }
}
@media screen and (max-width: 800px) {
  .section1 {
    /* background-image: none;
    background: #e5e5e5;
    background-size: cover;
    width: 100%;
    height: 80vh;
    position: relative; */
    background-image: url(./Frame\ 796.png);
    background-position: center;
  }
  .yolk {
    width: 80vw;
    height: auto;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .yolk__bag {
    display: none;
    /* width: 100vw;
    height: auto;
    position: absolute;
    bottom: 0%;
    left: 50%; */
    /* transform: translate(-50%, -25%); */
  }
}
@media screen and (width: 768px) {
  .section1 {
    background-image: none;
    background: #f1f1e9;
    background-size: cover;
    width: 100%;
    height: 98vh;
    position: relative;
  }
  .yolk {
    width: 80vw;
    height: auto;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .yolk__bag {
    display: block;
    width: 100vw;
    height: auto;
    position: absolute;
    bottom: 0%;
    left: 50%;
    /* transform: translate(-50%, -52%); */
  }
}
