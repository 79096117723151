@font-face {
  font-family: Lufga;
  src: url(../Lufga-Regular.otf);
}
.section4 {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 10000 !important;

  align-items: center;
  flex-direction: column;
  background-color: black;
  text-align: center;
  background-attachment: fixed;
}
.strip {
  display: none;
}
.mid__logo {
  /* width: 10px;
  height: auto; */
}
.section4__logo {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  /* left: 50%;
  transform: translateX(-50%); */
  margin-bottom: 100px;
}
.section4__body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* background-color: #141414; */

  text-align: left;
  padding: 100px 200px 0 200px;
}
.why {
  font-family: Lufga;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  /* identical to box height, or 58px */

  letter-spacing: 0.15em;
  z-index: 100;
  color: #ffffff;
}
.title {
  text-align: left;
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  z-index: 100;
  margin: 30px 0 20px 0;
  letter-spacing: 0.05em;

  color: #ffffff;
}
.text {
  font-family: Lufga;
  width: 1000px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: justify;
  z-index: 100;
  color: #e5e5e5;
}
.what {
  font-family: Lufga;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  z-index: 100;

  letter-spacing: 0.15em;
  margin-top: 200px;
  color: #ffffff;
}
.title2 {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  z-index: 100;
  margin: 30px 0 20px 0;
  letter-spacing: 0.05em;

  color: #ffffff;
}
.text ul li {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: justify;
  z-index: 100;
  color: #e5e5e5;
}
.your {
  font-family: Lufga;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  z-index: 100;
  margin-top: 200px;
  letter-spacing: 0.15em;

  color: #ffffff;
}
/* @media screen and (max-width: 800px) {
  .strip {
    display: none;
  }
  .section4__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #141414;
    text-align: center;
    padding: 50px 20px 0 20px;
  }
  .why {
    font-size: 30px;
    line-height: 120%;
  }
  .title {
    font-size: 20px;
    text-align: center !important;
  }
  .text {
    font-size: 15px;
    text-align: center;
  }
  .what {
    font-size: 30px;
  }
  .title2 {
    font-size: 20px;
    margin: 30px 0 20px 0;
  }
  .text ul li {
    font-size: 15px;
  }
  .your {
    font-size: 30px;
    margin-top: 200px;
  }
} */
@media screen and (max-width: 800px) {
  .parallax-outer {
    margin: 0 !important;
  }
  .section4__logo {
    margin-bottom: 50px;
    margin-top: 100px;
  }
  .section4__body {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    background-color: #000;
    text-align: center;
    padding: 100px 20px 0 20px !important;
  }
  .why {
    font-size: 30px;
    line-height: 120%;
    text-align: center !important;
  }
  .title {
    font-size: 20px;
    text-align: center !important;
  }
  .text {
    font-size: 15px;
    text-align: center !important;
  }
  .what {
    font-size: 30px;
    text-align: center !important;
  }
  .title2 {
    font-size: 20px;
    margin: 30px 0 20px 0;
  }
  .text ul li {
    font-size: 15px;
  }
  .title2 {
    text-align: center !important;
  }
  .your {
    font-size: 30px;
    margin-top: 200px;
    text-align: center !important;
  }
  .strip {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .section4__body {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    background-color: #000;
    text-align: center;
    padding: 200px 20px 0 20px;
  }
  .why {
    font-size: 30px;
    line-height: 120%;
    text-align: left;
  }
  .title {
    text-align: left;
    font-size: 20px;
  }
  .text {
    width: 100%;
    font-size: 12px;
    text-align: left;
  }
  .what {
    font-size: 30px;
    text-align: left;
  }
  .title2 {
    font-size: 20px;
    margin: 30px 0 20px 0;
    text-align: left;
  }
  .text ul li {
    font-size: 12px;
    text-align: left;
  }
  .text {
    text-align: left;
  }
  .your {
    font-size: 30px;
    margin-top: 200px;
    text-align: left;
  }
}
