.footer {
  position: relative;
  background: #141414;
  padding: 100px 100px 0 100px;
  text-align: center;
}

.left {
  text-align: left;
}
.left__text {
  font-family: Lufga;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  margin-top: 50px;
  color: #ffffff;
}
.right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 20vw;
}
.right1 {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  /* Neutral/20 */

  color: #ceccd6;
}
.right2 {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  /* Neutral/20 */

  color: #ceccd6;
}
.right3 {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  /* Neutral/20 */

  color: #ceccd6;
}
.right4 {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */
  /* border: 2px solid white; */
  display: flex;
  align-items: center;
  /* color: rgba(255, 255, 255, 0.75); */
}
input {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.75) !important;
  border: none !important;
}
.discText {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.75);
}
.discdown {
  text-align: center;
}
.footerDisc {
  height: 45px;
  width: auto;
}
.copy {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  padding: 50px 0 40px 0;
  color: #ffffff;
}
.right__text {
  text-align: left;
}
.box8 {
  margin-bottom: 100px;
}

form {
  /* This bit sets up the horizontal layout */
  display: flex;
  flex-direction: row;
  width: 50vw;
  /* This bit draws the box around it */
  border: 2px solid rgb(255, 255, 255);

  /* I've used padding so you can see the edges of the elements. */
  padding: 2px;
}

input {
  /* Tell the input to use all the available space */
  flex-grow: 2;
  /* And hide the input's outline, so the form looks like the outline */
  border: none;
}

input:focus {
  /* removing the input focus blue box. Put this on the form if you like. */
  outline: none;
}

.footerDisc {
  /* Just a little styling to make it pretty */
}
::placeholder {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.75);
}
@media screen and (max-width: 800px) {
  form {
    /* This bit sets up the horizontal layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
  }
  input {
    flex-grow: 0 !important;
    width: 30vw;
    height: auto;
  }
  .footer {
    position: relative;
    background: #141414;
    padding: 100px 50px 0 50px;
  }
  .leftGrid {
    text-align: center;
  }
  .logo__alien {
    width: 60vw;
    height: auto;
  }
  ::placeholder {
    font-size: 1.5vh;
  }
  .left__text {
    color: white;
    font-size: 12px;
    text-align: center;
    margin-top: 3vh;
    width: 100%;
    position: relative;
    /* right: 1.5vw; */
    font-weight: 700;
    margin-bottom: 5vh;
  }
  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40vw;
    margin-top: 10vh;
  }
  .right1 {
    font-size: 12px;
  }
  .right2 {
    font-size: 12px;
  }
  .right3 {
    font-size: 12px;
  }
  .right4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footerDisc {
  }
  .rightGrid1 {
    padding: 2vh;
  }
  .left {
    text-align: center;
  }
}
a {
  text-decoration: none;
}
@media screen and (width: 1024px) {
  .copy {
    text-align: center;
  }
  .footer {
    position: relative;
    background: #141414;
    padding: 100px 50px 0 50px;
  }
  .leftGrid {
    text-align: center;
  }
  .logo__alien {
    width: 60vw;
    height: auto;
  }
  .discText {
    color: rgba(255, 255, 255, 0.75);
    padding: 1vh;
    font-size: 1.5vh;
  }
  .left__text {
    color: white;
    font-size: 12px;
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 100%;
    position: relative;
    /* right: 1.5vw; */
    font-weight: 700;
  }
  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40vw;
  }
  .right1 {
    font-size: 12px;
  }
  .right2 {
    font-size: 12px;
  }
  .right3 {
    font-size: 12px;
  }
  form {
    /* This bit sets up the horizontal layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
  }
  input {
    flex-grow: 0 !important;
    width: 30vw;
    height: auto;
  }
  .rightGrid1 {
    padding: 2vh;
  }
  .left {
    text-align: center;
  }

  a {
    text-decoration: none;
  }
}
