.appbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 30px 0 30px 0;
  /* border-bottom: 3px solid black; */
}
.nav__logo {
  width: 325px;
  height: auto;
}
.line {
  margin: 0 20px 10px 20px;
}
.nav__btn {
  /* width: 163.19px;
  height: 47.95px; */
  background: #252525;
  border: 1.5468px solid #252525;
  box-sizing: border-box;
  color: white;
}
.nav__social {
  margin-left: 10px;
}
.disc {
}
.nav__twitter {
}
.nav__burger {
}
@media screen and (max-width: 800px) {
  .appbar {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    flex-direction: row;
    width: 80%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 30px 0 30px 0;
  }
  .nav__logo {
    width: 30vw;
    height: auto;
    margin-bottom: 4px;
  }
  .line {
    margin: 0 20px 5px 20px;
    width: 20px;
    display: none;
  }
  .nav__btn {
    width: 80px;
    height: auto;
    background: #252525;
    border: 1.5468px solid #252525;
    box-sizing: border-box;
    color: white;
    margin-left: 50px !important;
  }
  .nav__social {
    /* padding: 6px;
    width: 40px;
    height: auto; */
    margin-left: 5px !important;
  }
  .nav__twitter {
    width: 30px;
    height: auto;
  }
  .nav__burger {
    width: 30px;
    height: auto;
  }
  .nav__disc {
    width: 30px;
    height: auto;
  }
}
@media screen and (max-width: 1024px) {
  .appbar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 30px 0 30px 0;
  }
  .nav__logo {
    width: 28vw;
    height: auto;
  }
  .line {
    margin: 0 20px 5px 20px;
  }
  .nav__btn {
    margin-left: 20px;
  }
  .nav__social {
    margin-left: 10px;
  }
}
@media screen and (max-width: 479px) {
  .appbar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 30px 0 30px 0;
  }
  .nav__logo {
    width: 30vw;
    height: auto;
  }
  .line {
    margin: 0 20px 5px 20px;
  }
  .nav__btn {
    width: 60px;
    height: auto;
    background: #252525;
    border: 1.5468px solid #252525;
    box-sizing: border-box;
    color: white;
    margin-left: 20px;
  }
  .nav__social {
    /* padding: 6px;
    width: 40px;
    height: auto; */
    margin-left: 10px;
  }
  .nav__twitter {
    width: 20px;
    height: auto;
  }
  .nav__burger {
    width: 20px;
    height: auto;
  }
  .nav__disc {
    width: 20px;
    height: auto;
  }
}
@media screen and (width: 768px) {
  .appbar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 30px 0 30px 0;
  }
  .nav__logo {
    width: 30vw;
    height: auto;
  }
  .line {
    margin: 0 20px 5px 15px;
    display: flex;
  }
  .nav__line {
    width: 80px;
  }
  .nav__btn {
    width: 120px !important;
    height: auto;
    background: #252525;
    border: 1.5468px solid #252525;
    box-sizing: border-box;
    color: white;
    margin-left: 50px !important;
  }
  .nav__social {
    /* padding: 6px;
    width: 40px;
    height: auto; */
    margin-left: 10px;
  }
  .nav__twitter {
    width: 40px;
    height: auto;
  }
  .nav__burger {
    width: 40px;
    height: auto;
  }
  .nav__disc {
    width: 40px;
    height: auto;
  }
}
@media screen and (width: 820px) {
  .line {
    margin: 0 20px 5px 15px;
    display: flex;
  }
  .nav__line {
    width: 250px;
  }

  .nav__btn {
    width: 100px;
    height: auto;
    background: #252525;
    border: 1.5468px solid #252525;
    box-sizing: border-box;
    color: white;
    margin-left: 20px;
  }
  .nav__social {
    /* padding: 6px;
    width: 40px;
    height: auto; */
    margin-left: 10px;
  }
  .nav__twitter {
    width: 40px;
    height: auto;
  }
  .nav__burger {
    width: 40px;
    height: auto;
  }
  .nav__disc {
    width: 40px;
    height: auto;
  }
}
@media screen and (width: 1024px) {
  .appbar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 30px 0 30px 0;
  }
  .nav__logo {
    width: 28vw;
    height: auto;
  }
  .line {
    margin: 0 10px 5px 10px;
  }
  .nav__btn {
    width: 100px;
    height: auto;
    background: #252525;
    border: 1.5468px solid #252525;
    box-sizing: border-box;
    color: white;
    margin-left: 20px;
  }
  .nav__social {
    /* padding: 6px;
    width: 40px;
    height: auto; */
    margin-left: 10px;
  }
  .nav__twitter {
    width: 40px;
    height: auto;
  }
  .nav__burger {
    width: 40px;
    height: auto;
  }
  .nav__disc {
    width: 40px;
    height: auto;
  }
}
