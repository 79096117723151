@font-face {
  font-family: Lufga;
  src: url(../Lufga-Regular.otf);
}
.box2 {
  padding: 100px 100px 0 0;
  border-bottom: 2px solid yellow;
}
.section2 {
  /* background-image: url(./Landing\ Page.png); */
  background: #efefe7;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
.yolk__symbol {
  position: absolute;
  z-index: 10000 !important;
  top: -4%;
  left: 50%;
  width: 60px;
  height: auto;
  transform: translateX(-50%);
}
.section2__egg {
  /* width: 831.75px; */
  height: auto;
  position: relative;
  bottom: 0;
}
.section2__title {
  font-family: Lufga;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;

  line-height: 120%;
  /* or 58px */
  text-align: left;
  color: #2d2d2d;
}
.section2__subtitle {
  font-family: Lufga;
  font-style: normal;

  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
  text-align: left;
  letter-spacing: 0.15em;
  padding: 20px 0 40px 0;
  color: #000000;
}
.section2__text {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: left;
  color: #2d2d2d;
  padding: 0 0px 40px 0px;
  width: 672.5px;
}
.solsea__btn {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 24px;
  text-align: center;
  width: 300px;
  background: #252525;
}
.section2__logo {
  position: static;
  width: 25px;
  height: auto;
}
.viewsolsea {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  margin-left: 10px;

  color: #f3f2ef;
}

.section2__yolk {
  text-align: right;
}
.section2_btn {
  text-align: center;
}
@media screen and (max-width: 800px) {
  .solsea__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 27px; */
    text-align: center;
    width: 200px !important;
    background: #252525;
  }
}
@media screen and (width: 768px) {
  .solsea__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 27px; */
    text-align: center;
    /* width: 70% !important; */
    background: #252525;
  }
}
@media screen and (max-width: 1024px) {
  .yolk__symbol {
    position: absolute;
    z-index: 10000 !important;
    top: -3%;
    left: 50%;
    width: 60px;
    height: auto;
    transform: translateX(-50%);
  }
  .btns {
    text-align: center;
  }
  .section2_btn {
    text-align: center !important;
  }
  .box2 {
    padding: 100px 0px 200px 0px;
    border-bottom: 2px solid yellow;
  }
  .section2__egg {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 0;
    text-align: center;
    left: 0;
  }
  .section2_right {
    text-align: center;
  }

  .section2__title {
    font-family: Lufga;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;

    line-height: 120%;
    /* or 58px */
    text-align: center;
    color: #2d2d2d;
  }
  .section2__subtitle {
    font-family: Lufga;
    font-style: normal;

    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    /* or 38px */
    text-align: center;
    letter-spacing: 0.15em;
    padding: 20px 0 40px 0;
    color: #000000;
  }
  .section2__text {
    font-family: Lufga;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 31px;
    text-align: center;
    color: #2d2d2d;
    padding: 0 0 40px 0;
    width: 100%;
  }
  .solsea__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 20px; */
    /* width: 30%; */
    text-align: center;
    background: #252525;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .section2__logo {
    position: static;
    width: 25px;
    height: auto;
  }
  .viewsolsea {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;

    color: #f3f2ef;
  }
  .section2__yolk {
    text-align: center !important;
  }
  .section2_btn {
    text-align: center !important;
  }
}
