@font-face {
  font-family: pilat;
  src: url(../Lufga-Regular.otf);
}
@font-face {
  font-family: remake;
  src: url(../Lufga-Regular.otf);
}
.css-ofrcno-MuiGrid-root .MuiGrid-item {
  padding-left: 0px !important;
}
.faq__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border: none !important;
}
.faq {
}
.titleF {
  font-size: 16px;
  color: black;
  font-family: pilat;
  line-height: 150%;
  /* identical to box height */
  font-weight: 500;
  text-align: center;
  letter-spacing: 6%;

  color: #000000;
}
.MuiPaper-root {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.faq {
  color: #000;

  border: none;
}
.cont2 {
  background-color: transparent !important;
}
.cont {
  background-color: transparent !important;
}
.css-ahj2mt-MuiTypography-root {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 120%;
  /* identical to box height, or 29px */
  text-align: left;
  letter-spacing: 0.05em;

  color: #ffffff;
  font-family: remake !important;
}
.faqtext1 {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 24px !important;
  line-height: 120%;
  /* identical to box height, or 29px */
  text-align: left;
  letter-spacing: 0.05em;

  color: #ffffff;
  font-family: remake !important;
}
.faqtext3 {
  font-family: remake !important;
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  text-align: left;
  letter-spacing: 0.05em;

  color: #ffffff;
}

.faq__ans {
  font-family: remake !important;
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: justify;
  padding-left: 25px;
  color: #e5e5e5 !important;
}
.MuiTypography-root {
  margin-left: 10px !important;
}
.grid {
}
.MuiPaper-root {
  margin: 0 !important;
}
.MuiPaper-elevation {
  margin: 0 !important;
}
.faq__body {
  padding-bottom: 200px;
}
.sign_question {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.plus {
  width: 30px;
  height: auto;
}
.ques {
  font-family: remake !important;
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  letter-spacing: 0.05em;

  color: #ffffff;
  margin-left: 10px;
}
.MuiAccordionSummary-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse !important;
}
.MuiPaper-root {
  border: none !important;
}
@media screen and (max-width: 800px) {
  .css-ofrcno-MuiGrid-root .MuiGrid-item {
    padding-left: 34px !important;
  }
  .faq__body {
    margin: 3vh 0 3vh 0;
  }
  .grid {
    width: 90vw !important;
  }
  .css-ahj2mt-MuiTypography-root {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 120%;
    /* identical to box height, or 29px */
    text-align: left;
    letter-spacing: 0.05em;

    color: #ffffff;
    font-family: remake !important;
  }
  .cont2 {
    background-color: transparent !important;
    /* padding: 0.5vh; */
  }
  .cont {
    /* padding: 0.5vh; */
    background-color: transparent !important;
  }
  .faqtext1 {
    color: white !important;
    font-size: 12px;
    line-height: 120%;

    color: #fff;

    padding: 0vh;
    text-align: left !important;
    font-weight: normal;
  }
  .faqtext3 {
    padding: 0vh;
    font-size: 12px;
    line-height: 120%;
    color: #fff;
    color: #fff !important;
    font-weight: normal;

    text-align: left !important;
  }
  .faq__ans {
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    padding-left: 25px;
    color: #fff;
    color: white !important;
    font-weight: normal !important;
    text-align: left !important;
  }
  .titleF {
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.08em;

    color: #000000;
    font-weight: 500;
  }
}
@media screen and (width: 1024px) {
  .css-ahj2mt-MuiTypography-root {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 120%;
    /* identical to box height, or 29px */
    text-align: left;
    letter-spacing: 0.05em;

    color: #ffffff;
    font-family: remake !important;
  }
  .grid {
    width: 90vw !important;
  }
  .titleF {
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    text-align: left !important;
    letter-spacing: 0.08em;
    font-weight: 500;
  }

  .faqtext1 {
    color: white !important;
    font-size: 12px;
    line-height: 120%;
    font-weight: normal;
    padding: 0vh;
    text-align: left !important;
  }
  .faqtext3 {
    padding: 0vh;
    font-size: 12px;
    line-height: 120%;
    color: white !important;
    font-weight: normal;

    text-align: left !important;
  }
  .faq__ans {
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: white !important;
    font-weight: normal !important;
    text-align: left !important;
    padding-left: 25px;
  }
}
/* @media screen and (max-width: 912px) {
  .grid {
    width: 90vw !important;
  }
} */
