@font-face {
  font-family: Lufga;
  src: url(../Lufga-Regular.otf);
}
.navbar__body {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.nav {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 100000;
}
.nav__list {
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  flex-direction: column;
  background-color: rgba(34, 34, 34, 0.822);
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  padding: 10px 20px 20px 20px;
}
.nav__list div {
  color: white;
  font-family: Lufga;
  font-size: 20px;

  margin-top: 10px;
}
.nav__list__disappear {
  display: none;
}
.nav__disc {
  margin-right: 12px;
}
.nav__twitter {
  margin-top: 10px;
  margin-right: 12px;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
/* @media screen and (max-width: 1024px) {
  .hamburger-react {
    width: 50px !important;
    height: auto;
  }
} */
