@font-face {
  font-family: Lufga;
  src: url(../Lufga-Regular.otf);
}
.box6 {
  padding: 100px 50px 100px 0;
  background-attachment: fixed;
}
.community {
  font-family: Lufga;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 63px;
  /* identical to box height */

  text-transform: uppercase;
  text-align: left;
  color: #252525;
}
.community__text {
  font-family: Lufga;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  /* or 150% */
  margin: 20px 0 20px 0 !important;
  text-align: justify;
  width: 670.09px;
  color: rgba(37, 37, 37, 0.75);
}
.twitterCommunity {
  margin-left: 10px;
}
.community__btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row !important;
}

@media screen and (max-width: 1024px) {
  .box6 {
    padding: 100px 20px 100px 20px;
  }
  .twitterCommunity {
    margin-left: 10px;
  }
  .community {
    font-family: Lufga;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 63px;
    /* identical to box height */

    text-transform: uppercase;
    text-align: center;
    color: #252525;
  }
  .community__text {
    font-family: Lufga;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    /* line-height: auto; */
    /* or 150% */
    margin: 20px 0 20px 0 !important;
    text-align: center;
    width: 100%;
    color: rgba(37, 37, 37, 0.75);
  }
  .community__btn {
  }
  .section6_grid {
    text-align: center;
  }

  .community__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
  }
}
